<template>
    <div>
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6" style="padding-left: 26px !important;">
              <h1 class="m-0 text-dark" style="font-size: 2em;">
                Informasi Vending Machine
              </h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right ">
            
              </ol>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>
      <div v-if="dataVending?.length > 0">
        <data-tables-master-data
          :config="config"
          class="container-fluid"
          id="sampleTable"
        ></data-tables-master-data>
      </div>
      <!-- if informasi VM empty -->
      <div v-else class="image-empty"> 
        <img src="../../assets/img/emptyVM.png">
      </div>
    </div>
  </template>
  
  <script>
  const { console } = window;
  import DataTablesMasterData from "@components/DataTablesMasterData.vue";
  export default {
    components: {
      DataTablesMasterData,
    },
  
    data() {
      return {
        active: false,
        errorDialog: null,
        errorText: "",
        uploadFieldName: "file",
        error: undefined,
        loading: false,
        text_validation: null,
        maxSize: 1024,
        dataVending: null,
        disabled: false,
        isDetail: false,
        config: {
          columns: [
            {
              title: "ID Vending Machine",
              data: "vending_id",
            },
            {
              title: "Lokasi Vending Machine",
              data: "address",
            },
          ],
          url: this.Api.base + "/vending",
          order: [[0, "desc"]],
        },
      };
    },
    mounted() {
      this.getDataVending()
    },
    props: {
      value: Object,
    },
    methods: {
      add() {},
      onClick(row) {},
      refresh() {
        this.config = {
          url: this.Api.base + "/vending",
        };
      },
      async getDataVending() {
        const res  = await Api.get('/vending')
        this.dataVending = res.data.content
      }
    },
  };
  </script>
  